import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-about-us',
  templateUrl: './about-us.component.html',
  styleUrls: ['./about-us.component.scss']
})
export class AboutUsComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

  slogans = [
    { header: "INTEGRITY", content: "To be fair, transparent, and ethical in our conduct with all our stakeholders" },
    {
      header: "INNOVATION",
      content: "Commitment to innovation that ensures sustainable growth for the entire digital ecosystem",
    },
    {
      header: "EMPOWERMENT OF PEOPLE",
      content:
        "A work environment that empowers people to transform challenges into opportunities",
    },
    {
      header: "EMBRACING DIVERSITY",
      content:
        "Embraces cultural diversity to enrich individuals and unlock their creativity",
    },
    {
      header: "COMMITMENT TO SUSTAINABILITY",
      content:
        "Commitment to a sustainable digital ecosystem that benefits all",
    },
    {
      header: "SHARED & ENDURING PROSPERITY FOR ALL",
      content:
        "Ensuring enduring prosperity for all, through shared knowledge, value-creating relationships, and sustainable growth",
    }
  ];
}
