import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-services',
  templateUrl: './services.component.html',
  styleUrls: ['./services.component.scss']
})
export class ServicesComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

  services = [
    {
      name: "Next Gen App Development",
      description1: `Sorridi Consulting is a leading Microsoft Technology Service Provider with proven expertise in Microsoft Technologies such as .Net Core, Microsoft Azure, Azure SQL, Azure IoT, Payment Gateway Solution, Content Management System, Microservices, Microdots, Microsoft .Net Frameworks from 2.0 to 4.8, C# 8, Web API 2.2/5.2, WCF, MVC, VB6, ASP.net, VB.net, OOA / OOD, WPF & Silverlight, etc. Our developers are proficient in Microsoft Dynamics CRM, and other Microsoft Technology stacks.`,
      description2: `Our expertise in Microsoft Technology Services guarantees robust & reliable business applications that deliver sustainable results, faster. Sorridi Consulting’s proven expertise in Microsoft Technology Services guarantees robust digital solutions that are future-ready
      Over the last few years, Sorridi Consulting has served scores of clients from varied business verticals. We have been providing customized Microsoft Technology Services and Cloud-based solutions that streamline business processes that are easy to manage. Our digital applications/solutions, reform existing processes & strategies that are inadequate to meet the upcoming challenges.`,
      description3: `Our Expertise In Microsoft Technology Services: .Net Core,Microsoft Azure,Microsoft Sharepoint,Architecture,Microsoft Technologies,Design & UX`,
      description4: `Why Choose us as your Microsoft Technology Service Provider?
      Domain Expertise,
      Technical Efficiency,
      Agile Methodology,
      Flexible Engagement,
      You Choose the Resources`,
    },
    {
      name: "Open-Source Development",
      description1: `Sorridi Consulting helps you build innovative applications and web properties by leveraging a robust open-source development platform. Unmatched features, affordable rate, high usability, and rich performance are some of the essential points that make open-source software development highly preferable among our clients.`,
      description2: `Our proven delivery model brings a considerable reduction in engineering efforts and costs. Sorridi Consulting Open-Source Competency Center has developed frameworks and approaches that can be utilized for customer engagements to build a wide variety of applications covering web, mobile, windows, and service-oriented and rich internet applications.`,
      description3: `Being the fastest-growing open-source development company, we offer highly scalable and customized Open Source Next-Gen App Development Services for start-ups and large enterprises. We make sure to deliver robust solutions from scratch, providing integrated adaptable CMS components and solid open-source codes and apps for your enterprises.`,
      description4: `Our motto is to build a digital ecosystem that runs on shared prosperity and sustainable growth.
      Our solution for open-source environments delivers the product according to the needs of the business. Let us handle your project and lead your business towards digital prosperity with open-source development!`,
    },
    {
      name: "Modernization",
      description1: `Achieve More Scalability, Efficiency And Productivity With Your Applications
      Over the years, We have been developing custom applications and delivering comprehensive services for application support, integration, and upgrade. By having rich experience in multiple industries (from retail to manufacturing to banking and healthcare), our team can assist you in defining clear integration goals, building up a robust data integration strategy, and ensuring its orderly implementation.`,
      description2: `Sorridi Consulting fuels your enterprise’s digital transformation initiatives through end-to-end integration of applications, workflows, and databases. We help you excel at an integration initiative of any type with a reliable and robust integration solution catered to your specific context and business goals.`,
      description3: `Our IT specialist will take complete ownership of installation, configuration, and troubleshooting integrations with external systems. We allow integration among all applications and systems across the enterprise to ensure sustainable digital prosperity.`,
      description4: ``,
    },
    {
      name: "Cross-Platform Apps Development Services",
      description1: `We are an ISO-Certified Cross-Platform App Development Company with many years of experience developing apps for a wide range of industry domains & mobile platforms. With our affordable Cross-Platform App Development Services, you are assured of a faster-time-to-market scalable solution that increases your audience reach. Cross-Platform Apps run on multiple mobile operating systems. Our expertise in Cross-Platform Apps ensures your audience gets consistent user experience across all devices.`,
      description2: `We help businesses make informed decisions about their cross-platform app development strategy. 
      We provide expert guidance on choosing the right technologies, frameworks, and development methodologies based on our client's business goals, budget, and target audiences.
      Our Services include:
      App Consulting,
      Custom App Development,
      Cross-Platform UI/UX Design,
      App Porting,
      Cross-platform App Testing,
      App Enhancement,
      App Migration,
      Maintenance and Support
      `,
      description3: `These apps use a single codebase that runs on multiple platforms and are built using HTML, CSS, and JavaScript. 
      They can be wrapped in a native container to run as a native app on different platforms. Following are the frameworks we use to build such apps:
      Hybrid Apps,
      Progressive Web Apps,
      React Native Apps,
      Xamarin Apps,
      Flutter Apps,
      PhoneGap/Cordova Apps,
      Windows Universal Apps
      `,
      description4: `Sorridi Consulting having many years of experience in modernizing legacy systems, software, and applications 
      pertaining to various business domains. We also provide maintenance & support services to legacy I.T. systems & applications. 
      At Sorridi Consulting, we deploy methodologies and strategies that ensure seamless upgradation of legacy systems to a modern I.T. environment 
      that incorporates Data-warehousing, IoT, and Cloud. Our legacy modernization services make your application scalable, secure, robust and future-ready.
      `,
    },
    {
      name: "DevOps Consulting Services",
      description1: `Sorridi Consulting adopts an integrated approach for software delivery and CX transformation. We help in integrating an organization’s culture, tools, and processes. With our DevOps as a consulting service offering, we ensure rapid on-boarding of applications, increase efficiency, and decrease cost by automating the end-to-end delivery pipeline, and empower continuous planning, development, and integration across leading cloud platforms.`,
      description2: `Our DevOps consulting services assist organizations with lining up the business objectives and producing high-quality software-based products and services. By working with our skilled engineers, your business will be able to take on the DevOps philosophy that allows you to stay in control, save money, and prosper digitally in the multifaceted nature of reconciling agile culture.`,
      description3: `We create a DevOps Assessment roadmap to set specific, quantifiable goals at the beginning of each stage and identify the traceable metrics. All stakeholders are involved at this stage to bring a meaningful impact to the discussion and minimize redundancies.`,
      description4: `Businesses can gain from an automated work environment in several ways. As a ServiceNow Managed Service Provider, we aim to increase this benefit. ServiceNow Managed Service facilitates customization, reduces time spent on repetitive processes, boosts productivity, and fosters work integration. Most importantly, ServiceNow Managed Services does away with human errors in critical business workflows. The ServiceNow platform has everything you need to enhance the service delivery at your business. We have expertise in the usage of tools like Jenkins, TeamCity, GitLab, Codeship, Bamboo, etc.`,
    },
  ]
}
