import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-team',
  templateUrl: './team.component.html',
  styleUrls: ['./team.component.scss']
})
export class TeamComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

  // management = [
  //   {
  //     name: "Pankaj Shrivastava",
  //     img: "assets/pankaj.jpeg",
  //     description: "Pankaj is playing role in giving strategic direction to the organization which helps the overall business to create value for all the stakeholders \
  //     of the business. He is also playing role in building different verticals of business with in the organization",
  //     designation: "Chairman and Managing Director"
  //   }
  // ];

  team = [
    {
      name: "Consultants",
      description: "We have a team of consultants who are highly experienced and specialize in different sectors of the economy. \
      With rich experience our team is able to provide the right resources to our clients even in the short notices. \
      Equipped with newest technological advancements and techniques, we provide extensive quality assurance in our passion to serve the clients. \
      Our consultants are continuously focussing on changing the needs of the clients so that we can contribute in providing the right resources to our clients. \
      Our team takes time to make a comprehensive understanding of clients’ business and is committed to provide unparalleled quality and services related to recruitment \
      and selection. We assist our Clients in creating and developing their talents"
    },
    {
      name: "IT Team",
      description: "Our experienced IT team is able to deliver the projects as per the needs of the clients. To deliver the good quality IT solutions we have focussed on \
      recruiting the experienced resources from various IT giants. Sorridi Consulting continuously focusses on recruiting best talent from the industry so that we are \
      always in a position to deliver the IT solutions to the clients. Having the experienced people in the team helps us in developing the IT solutions with good quality \
      and within the agreed time frame."
    },
    {
      name: "Business Development Team",
      description: "We have a team of Business Development Managers who bring us the resource requirement from prestigious organizations. \
      These are the key people in developing the business contacts for us"
    }
  ];
}
