<div fxLayout="column" fxLayoutGap="10px" style="margin: 20px 20px">
  <div>
    <h2 class="title">ABOUT US</h2>
    <h1 class="sub-title" style="text-align: center">
      Your smile is precious.
    </h1>
  </div>
  <div class="about-us-content" fxLayout="column" fxLayoutAlign="start start">
    <p>
      Sorridi Consulting is a leading IT solution provider and consulting company delivering deep domain expertise, 
      digital expertise, and innovation to help enterprises leverage digital processes and excel in their business.
    </p>
    <p>
      Since 2018, we have built lasting relationships that have kept us on the cutting edge of innovation. 
      We will endeavour to continue to inspire, transform, and create synergies that shape the digital 
      world and ensure shared prosperity for all.
    </p>
  </div>
  <div fxLayout="column" fxLayoutAlign="start start">
    <p *ngFor="let slogan of slogans">
      <span class="sub-title">{{ slogan.header }}: </span>
      <span class="slogan">{{ slogan.content }}</span>
    </p>
  </div>
</div>
<div
  fxLayout="column"
  fxLayoutAlign="start start"
  fxLayoutGap="10px"
  class="banner"
>
  <div>
    <h2>Our Beliefs</h2>
  </div>
  <p>
    <span>People Power:</span> People come with skills, experiences, and insights that help redefine possibilities, thereby delivering innovative solutions.
  </p>
  <p>
    <span>People are Talented:</span> Given the opportunity, people will achieve excellence in their areas of talent.
  </p>
  <p>
    <span>We are Committed to:</span> Achieving an optimum balance between growth targets and profit margins.
    Accelerating the advancement of in-house expertise to keep up with technological upgrades.
    Actively contributing to the well-being of communities at large
  </p>
  <p>
    <span>Vision:</span> To build a sustainable digital ecosystem that promotes shared prosperity.
  </p>
  <p>
    <span>Mission:</span> Develop best-in-class solutions that ensure enduring growth and shared prosperity.
  </p>
</div>
