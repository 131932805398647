<div
  fxLayout="column"
  fxLayoutAlign="start stretch"
  fxLayoutGap="10px"
  style="padding: 20px 20px; background-color: #9fa8da"
>
  <div fxLayout="row" fxLayoutAlign="center center">
    <h2 class="title">Meet Our Team</h2>
  </div>

  <!-- <div
    fxLayout="row"
    fxLayout.xs="column"
    fxLayoutAlign="space-evenly center"
    fxLayoutGap="20px"
  >
    <mat-card
      *ngFor="let member of management"
      style="min-width: 300px; max-width: 300px"
    >
      <mat-card-header style="justify-content: center">
        <mat-card-title>{{ member.name }}</mat-card-title>
        <mat-card-subtitle *ngIf="member.designation">{{
          member.designation
        }}</mat-card-subtitle>
      </mat-card-header>
      <mat-card-content style="text-align: center" class="member-card-content">
        <img *ngIf="member.img" [src]="member.img" class="responsive-img" />
        <p>
          {{ member.description }}
        </p>
      </mat-card-content>
    </mat-card>
  </div> -->

  <div
    fxLayout="row"
    fxLayout.xs="column"
    fxLayoutAlign="space-evenly center"
    fxLayoutGap="20px"
  >
    <mat-card *ngFor="let member of team" class="member-card">
      <mat-card-header style="justify-content: center">
        <mat-card-title>{{ member.name }}</mat-card-title>
        <mat-card-subtitle *ngIf="member.designation">{{
          member.designation
        }}</mat-card-subtitle>
      </mat-card-header>
      <mat-card-content style="text-align: center" class="member-card-content">
        <p>
          {{ member.description }}
        </p>
      </mat-card-content>
    </mat-card>
  </div>
</div>
