<div fxLayout="column" fxLayoutAlign="start center" fxLayoutGap="10px" style="margin: 20px 20px">
  <h2 class="title">Services</h2>

  <ng-container *ngFor="let service of services">
    <mat-card style="min-width: 70vw; max-width: 70vw;">
      <mat-card-header style="justify-content: center;">
        <mat-card-title class="sub-title">{{service.name}}</mat-card-title>
      </mat-card-header>
      <mat-card-content class="training-content">
        <p *ngIf="service.description1 != ''">{{service.description1}}</p>
        <p *ngIf="service.description2 != ''">{{service.description2}}</p>
        <p *ngIf="service.description3 != ''">{{service.description3}}</p>
        <p *ngIf="service.description4 != ''">{{service.description4}}</p>
      </mat-card-content>
    </mat-card>
  </ng-container>
</div>
