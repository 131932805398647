<div
  fxLayout="column"
  fxLayoutGap="10px"
  style="padding: 20px 20px; background-color: #ee1515"
>
  <div>
    <h2 class="title">CONTACT US</h2>
  </div>

  <div fxLayout="column" fxLayoutAlign="space-evenly center" class="contact">
    <div fxLayout="row" fxLayoutAlign="start start">
      <mat-icon>settings_phone</mat-icon>&nbsp;
      <span style="margin-top: 2px;">Phone: <a href="tel:+917385640929">+91 7385640929</a></span>
    </div>
    <div fxLayout="row" fxLayoutAlign="start start">
      <mat-icon>mail</mat-icon>&nbsp;
      <span style="margin-top: 2px;">Mail: <a href="mailto:contact@sorridiconsulting.com">contact@sorridiconsulting.com</a></span>
    </div>
    <div fxLayout="row" fxLayoutAlign="start start">
      <mat-icon>location_city</mat-icon>&nbsp;
      <span style="margin-top: 2px;">Office: <a href="https://g.page/CollabWorkspace?share">
        Collab Workspace - 02, 4th Floor, City Vista Tower-B, Fountain Road, near World Trade Center, Kharadi, Pune, Maharashtra 411014
      </a></span>
    </div>
  </div>
</div>
