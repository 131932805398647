import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-caraousel',
  templateUrl: './caraousel.component.html',
  styleUrls: ['./caraousel.component.scss']
})
export class CaraouselComponent implements OnInit {

  slides = [
    { image: "assets/caraousel1.jpg" },
    { image: "assets/caraousel2.jpg" },
    { image: "assets/caraousel3.jpg" },
    { image: "assets/caraousel4.jpg" },
  ];

  constructor() { }

  ngOnInit(): void {
  }

}
