<div fxLayout="column" fxLayoutGap="10px" class="career-section">
  <div>
    <h2 class="title">CAREERS</h2>
  </div>

  <div fxLayout="row" class="career-content">
    <p>
      It's a great time to be in the information technology industry if you want to gain
      experience with us. We have opendoors for every kind of IT personal.
      Just give us a ping and you're in.
    </p>
  </div>
</div>
