<div>
  <mat-toolbar color="primary">
    <button
      mat-icon-button
      (click)="sidenav.toggle()"
      fxShow="true"
      fxHide.gt-sm
    >
      <mat-icon>menu</mat-icon>
    </button>

    <span style="margin-top: 10px; margin-right: 10px">
      <img
        src="assets/logo.png"
        style="border-radius: 50%"
        height="30px"
        width="30px"
      />
    </span>

    <span>Sorridi Consulting</span>

    <span class="example-spacer"></span>

    <div fxShow="true" fxHide.lt-md="true">
      <!-- The following menu items will be hidden on both SM and XS screen sizes -->
      <ng-container *ngFor="let section of sections">
        <a [href]="section.ref" mat-button class="section-btn">
          <mat-icon>{{ section.icon }}</mat-icon>
          {{ section.text }}
        </a>
      </ng-container>
    </div>

    <!-- <div fxShow="true" fxHide.gt-sm="true">
                <a href="#" (click)="sidenav.toggle()">Show Side Menu</a>
            </div> -->
  </mat-toolbar>

  <mat-sidenav-container fxFlexFill class="example-container">
    <mat-sidenav #sidenav fxLayout="column">
      <div fxLayout="column">
        <ng-container *ngFor="let section of sections">
          <a [href]="section.ref" mat-button color="primary" (click)="sidenav.toggle()">
            {{ section.text }}
          </a>
        </ng-container>
        <a (click)="sidenav.toggle()" href="#" mat-button color="warn">Close</a>
      </div>
    </mat-sidenav>
    <mat-sidenav-content fxFlexFill>
      <app-caraousel></app-caraousel>
      <app-about-us id="about-us"></app-about-us>
      <app-services id="services"></app-services>
      <app-team id="team"></app-team>
      <app-career id="careers"></app-career>
      <app-contact-us id="contact-us"></app-contact-us>
    </mat-sidenav-content>
  </mat-sidenav-container>
</div>

<div fxLayout="row" fxLayoutAlign="end center" style="padding: 20px;">
  <mat-icon>copyright</mat-icon>&nbsp;
  <span style="margin-top: 2px;">Sorridi Consulting Pvt Ltd.</span>
</div>
