import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.scss']
})
export class MainComponent implements OnInit {

  sections = [
    { text: "About Us", icon: "info", ref: '#about-us' },
    { text: "Services", icon: "miscellaneous_services", ref: '#services' },
    { text: "Team", icon: "groups", ref: '#team' },
    { text: "Careers", icon: "trending_up", ref: '#careers' },
    { text: "Contact Us", icon: "contacts", ref: '#contact-us' },
  ];
  title = "sorridi-consulting-2";

  constructor() { }

  ngOnInit(): void {
  }

}
